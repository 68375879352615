const localStorage = window.localStorage

export const getIsAcceptPolicy = (): boolean => {
  return localStorage.getItem('is_accept_policy') === 'true'
}

export const saveIsAcceptPolicy = (isAcceptPolicy: boolean) => {
  localStorage.setItem('is_accept_policy', isAcceptPolicy.toString())
}

export const removeIsAcceptPolicy = () => {
  localStorage.removeItem('is_accept_policy')
}

export const getIsAcceptEmailMarketing = (): boolean => {
  return localStorage.getItem('is_accept_email_marketing') === 'true'
}

export const saveIsAcceptEmailMarketing = (IsAcceptEmailMarketing: boolean) => {
  localStorage.setItem(
    'is_accept_email_marketing',
    IsAcceptEmailMarketing.toString()
  )
}

export const removeIsAcceptEmailMarketing = () => {
  localStorage.removeItem('is_accept_email_marketing')
}

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBZmfA2jkrEUbLFu9A_I0v5W1G2dgal-Co',
  appId: '1:387229734852:web:af261898b8543083faf691',
  authDomain: 'ywc18-register.firebaseapp.com',
  databaseURL: 'https://ywc18-register.firebaseio.com',
  measurementId: 'G-TB84TLMJ1W',
  messagingSenderId: '387229734852',
  projectId: 'ywc18-register',
  storageBucket: 'ywc18-register.appspot.com'
}

const firebaseInstance = firebase.initializeApp(firebaseConfig)

export const auth = firebaseInstance.auth()

export const firestore = firebase.firestore()

export const storage = firebaseInstance.storage()

export default firebaseInstance
